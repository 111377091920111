// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState, store } from "store";
import { StateProps, Survey } from "./types";
// Schemas
import { SurveySchema, SurveyStatus } from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { z } from "zod";

let polling = 0;
let pollingTO: ReturnType<typeof setTimeout | typeof clearTimeout> = undefined;

/**
 * Fetch Study by Id
 * Example of an async action / thunk
 * @example await/void dispatch(getStudy({ mainApi, studyId }}));
 */
export const getSurveyById = createAsyncThunk<
  Partial<StateProps>,
  { surveyId: string },
  { state: RootState }
>("surveys/fetch-by-id", async ({ surveyId }, { getState }) => {
  let data: StateProps["data"] = getState().surveys.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Survey }>>({
    schema: z.object({
      status: z.literal(200),
      body: SurveySchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/surveys/${surveyId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      currentSurvey: result.response.body,
    };
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Generate Synthetic Users
 * Example of an async action / thunk
 * @example await/void dispatch(generateSyntheticUsers({ mainApi, projectId, quantity, audiencesIds, problemsIds }}));
 */
export const pollSurveyAudiences = createAsyncThunk<
  Partial<StateProps>,
  {
    surveyId: string;
  },
  { state: RootState }
>("surveys/poll", async ({ surveyId }, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().surveys.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  // clear timeout if pollingTO is defined on init
  if (pollingTO !== undefined) {
    pollingTO = clearTimeout(pollingTO);
  }

  // enable polling
  polling += 1;

  const mainApi = getMainApi();

  // Total two minutes (60 * 2000msecs)
  const result = await mainApi.fetch<
    z.ZodType<{
      status: 200;
      body: Survey;
    }>
  >({
    schema: z.object({
      status: z.literal(200),
      body: SurveySchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/surveys/${surveyId}`,
  });

  // Error handling
  if (result.failure) {
    error = result.failure;
  } else {
    // Success
    const payload = result.response.body;

    if (payload.status === SurveyStatus.FAILED) {
      data = {
        ...data,
        currentSurvey: payload,
      };
    } else if (payload.status !== SurveyStatus.FINISHED) {
      dispatch(generatingSurvey());

      data = {
        ...data,
        currentSurvey: payload,
      };

      pollingTO = setTimeout(() => {
        if (polling) {
          polling -= 1;

          dispatch(pollSurveyAudiences({ surveyId }));
        }
      }, 10000);
    } else {
      // Status is done
      // disable polling
      polling -= 1;
      if (pollingTO !== undefined) {
        pollingTO = clearTimeout(pollingTO);
      }
      dispatch(resetGeneratingSurvey());

      data = {
        ...data,
        currentSurvey: payload,
      };
    }
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Fetch Study by Id
 * Example of an async action / thunk
 * @example await/void dispatch(getStudy({ mainApi, studyId }}));
 */
export const getProjectSurveys = createAsyncThunk<
  Partial<StateProps>,
  { projectId: string },
  { state: RootState }
>("surveys/fetch-all", async ({ projectId }, { getState }) => {
  let data: StateProps["data"] = getState().surveys.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Survey[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(SurveySchema),
    }),
    skipParsing: false,
    method: "GET",
    path: `/surveys/history/${projectId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      projectSurveys: result.response.body,
    };
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Study description
 * Example of an async action / thunk
 * @example await/void dispatch(updateStudyDescription({ mainApi, studyId, description }}));
 */
export const createSurvey = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    name: string;
    description?: string;
    onSuccess: (surveyId: string) => void;
  },
  { state: RootState }
>("surveys/create", async ({ projectId, name, description, onSuccess }, { getState }) => {
  let data: StateProps["data"] = getState().surveys.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Survey }>>({
    schema: z.object({
      status: z.literal(200),
      body: SurveySchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/surveys/create/${projectId}`,
    body: {
      project_id: projectId,
      name,
      description: description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      currentSurvey: result.response.body,
      projectSurveys: [result.response.body, ...(data?.projectSurveys || [])],
    };

    onSuccess(result.response.body.id);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Study description
 * Example of an async action / thunk
 * @example await/void dispatch(updateStudyDescription({ mainApi, studyId, description }}));
 */
export const updateSurveyAudiences = createAsyncThunk<
  Partial<StateProps>,
  {
    surveyId: string;
    audiencesIds: string[];
  },
  { state: RootState }
>("surveys/update-audiences", async ({ surveyId, audiencesIds }, { getState }) => {
  let data: StateProps["data"] = getState().surveys.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Survey }>>({
    schema: z.object({
      status: z.literal(200),
      body: SurveySchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/surveys/updateAudiences/${surveyId}`,
    body: audiencesIds,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      currentSurvey: result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Study description
 * Example of an async action / thunk
 * @example await/void dispatch(updateStudyDescription({ mainApi, studyId, description }}));
 */
export const updateSurveyData = createAsyncThunk<
  Partial<StateProps>,
  {
    surveyId: string;
    surveyData: unknown;
  },
  { state: RootState }
>("surveys/update-data", async ({ surveyId, surveyData }, { getState }) => {
  let data: StateProps["data"] = getState().surveys.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Survey }>>({
    schema: z.object({
      status: z.literal(200),
      body: SurveySchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/surveys/updateData/${surveyId}`,
    body: surveyData,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      currentSurvey: result.response.body,
    };

    // Side Effects
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Study description
 * Example of an async action / thunk
 * @example await/void dispatch(updateStudyDescription({ mainApi, studyId, description }}));
 */
export const startSurvey = createAsyncThunk<
  Partial<StateProps>,
  {
    quantity: number;
    surveyId: string;
  },
  { state: RootState }
>("surveys/start-survey", async ({ quantity, surveyId }, { getState }) => {
  let data: StateProps["data"] = getState().surveys.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Survey }>>({
    schema: z.object({
      status: z.literal(200),
      body: SurveySchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/surveys/startSurvey/${surveyId}`,
    body: {
      quantity,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      currentSurvey: result.response.body,
    };

    // Side Effects
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Fetch Audiences by project Id
 * Example of an async action / thunk
 * @example await/void dispatch(getAudiences({ mainApi, studyId }}));
 */
export const exportSurvey = createAsyncThunk<
  Partial<StateProps>,
  { surveyId: string; fileExtension: string },
  { state: RootState }
>("surveys/export", async ({ surveyId, fileExtension }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.literal(200),
      body: z.unknown(),
    }),
    method: "GET",
    path: `/surveys/download/${surveyId}?file_extension=${fileExtension}`,
    skipParsing: true,
    isFileDownload: true,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    const blob = new Blob([result.response.body as Blob], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${surveyId}.${fileExtension}`;
    a.click();
    // Remove the link after the download starts
    setTimeout(() => {
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    error,
  };
});

/**
 * Update Study description
 * Example of an async action / thunk
 * @example await/void dispatch(updateStudyDescription({ mainApi, studyId, description }}));
 */
export const cloneSurvey = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    name: string;
    description?: string;
    surveyData: unknown;
    onSuccess: (surveyId: string) => void;
  },
  { state: RootState }
>(
  "surveys/update-data",
  async ({ projectId, name, description, surveyData, onSuccess }, { getState }) => {
    let data: StateProps["data"] = getState().surveys.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    const createSurveyResult = await mainApi.fetch<z.ZodType<{ status: 200; body: Survey }>>({
      schema: z.object({
        status: z.literal(200),
        body: SurveySchema,
      }),
      skipParsing: false,
      method: "POST",
      path: `/surveys/create/${projectId}`,
      body: {
        project_id: projectId,
        name,
        description: description,
      },
    });

    if (createSurveyResult.failure) {
      error = createSurveyResult.failure;
    } else {
      const { id: surveyId } = createSurveyResult.response.body;

      const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Survey }>>({
        schema: z.object({
          status: z.literal(200),
          body: SurveySchema,
        }),
        skipParsing: false,
        method: "POST",
        path: `/surveys/updateData/${surveyId}`,
        body: surveyData,
      });

      if (result.failure) {
        error = result.failure;
      } else {
        data = {
          ...data,
          currentSurvey: result.response.body,
        };

        onSuccess(surveyId);
        // Side Effects
      }
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Reset Study
 * @example dispatch(resetStudy());
 */
export const resetSurveys = createAction("surveys/reset");

export const generatingSurvey = createAction("surveys/generating");

export const resetGeneratingSurvey = createAction("surveys/reset-generating");

export const killSurveyPolling = () => {
  polling = 0;
  store.dispatch(resetGeneratingSurvey());
  if (pollingTO !== undefined) {
    pollingTO = clearTimeout(pollingTO);
  }
};
