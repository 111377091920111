// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  generateKnowledgeGraph,
  resetKnowledgeGraph,
  pollKnowledgeGraph,
  generatingKnowledgeGraph,
  resetGeneratingKnowledgeGraph,
} from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetKnowledgeGraph, () => initialState);
  builder.addCase(generatingKnowledgeGraph, (state) => ({
    ...state,
    generating: true,
  }));
  builder.addCase(resetGeneratingKnowledgeGraph, (state) => ({
    ...state,
    generating: false,
  }));
  builder
    // Loading start
    .addMatcher(isAnyOf(generateKnowledgeGraph.pending, pollKnowledgeGraph.pending), (state) => ({
      ...state,
      loading: state.loading + 1,
    }))
    // Get Study fulfilled
    .addMatcher(
      isAnyOf(generateKnowledgeGraph.fulfilled, pollKnowledgeGraph.fulfilled),
      (state, action) => ({
        ...state,
        error: action.payload.error,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        generateKnowledgeGraph.fulfilled,
        generateKnowledgeGraph.rejected,
        pollKnowledgeGraph.fulfilled,
        pollKnowledgeGraph.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading > 0 ? state.loading - 1 : 0,
      })
    );
});
