// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import {
  ResearchAssistant,
  ResearchAssistantResponse,
  ResearchAssistantStatus,
  ResearchAssistantStudyType,
  StateProps,
} from "./types";
// Schemas
import { ResearchAssistantResponseSchema, ResearchAssistantSchema } from "./schemas";
// Initial State
import { initialState } from "./initial-state";

// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { z } from "zod";

/**
 * Fetch Study by Id
 * Example of an async action / thunk
 * @example await/void dispatch(getStudy({ mainApi, studyId }}));
 */
export const initAssistant = createAsyncThunk<Partial<StateProps>, undefined, { state: RootState }>(
  "research-assistant/init",
  async () => {
    let data: StateProps["data"] = initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    const result = await mainApi.fetch<z.ZodType<{ status: 200; body: ResearchAssistant }>>({
      schema: z.object({
        status: z.literal(200),
        body: ResearchAssistantSchema,
      }),
      skipParsing: false,
      method: "POST",
      path: `/researchAssistant/`,
    });

    if (result.failure) {
      error = result.failure;
    } else {
      data = {
        id: result.response.body.id,
      };
    }
    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Fetch Study by Id
 * Example of an async action / thunk
 * @example await/void dispatch(getStudy({ mainApi, studyId }}));
 */
export const sendAssistantMessage = createAsyncThunk<
  Partial<StateProps>,
  {
    assistantId: string;
    message: string;
    study_type: ResearchAssistantStudyType;
    isLiteral?: boolean;
  },
  { state: RootState }
>(
  "research-assistant/send-assistant-message",
  async ({ assistantId, message, study_type, isLiteral }) => {
    let data: StateProps["data"] = initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    const result = await mainApi.fetch<z.ZodType<{ status: 200; body: ResearchAssistantResponse }>>(
      {
        schema: z.object({
          status: z.literal(200),
          body: ResearchAssistantResponseSchema,
        }),
        skipParsing: false,
        method: "POST",
        path: `/researchAssistant/chat/${assistantId}`,
        body: {
          message,
          study_type,
          lock: isLiteral,
        },
      }
    );

    if (result.failure) {
      error = result.failure;
    } else {
      const { next_question, audience, research_goal, problems, solution, custom_script, status } =
        result.response.body;

      data = {
        audience,
        problems,
        solution,
        question: next_question,
        researchGoal: research_goal,
        customScript: custom_script,
        status: status ? (status as ResearchAssistantStatus) : undefined,
      };
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Reset Study
 * @example dispatch(resetStudy());
 */
export const resetResearchAssistant = createAction("researchAssistant/reset");
